import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { StaticTemplate } from '../../../templates/staticTemplate'
import { Layout } from '../../../components/layouts/layout'
import { PageMeta } from '../../../components/page-meta'
import { CalculatorSectionModule } from '../../../components/modules/CalculatorSectionModule'
import { HomeEquityCalculator } from '../../../components/calculators'
import { anchorLinkSections, CrossSellToutEquity } from './constants'
import { usePages } from '../constants'

const HomeEquityPage = ({ pageContext, location }) => {
  const [t] = useTranslation()
  const { calculatorsPage, mortgagePage } = usePages()

  const pageData = [
    {
      sectionTitle: t('HomeEquityPage.subTitle', {
        defaultValue: "How much can I borrow from my home's equity?",
      }),
      richBody: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('HomeEquityPage.textContent', {
                  defaultValue:
                    'What if your house was made of money? It just might be. Use this home equity calculator to see how much you might be able to borrow from your home equity (the value of your home above what you still owe on your loan).',
                }),
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('HomeEquityPage.textContent2a', {
                  defaultValue:
                    'While this calculator can help you understand how much you might be able to borrow, all numbers provided here are estimates. Please ',
                }),
                data: {},
              },
              {
                nodeType: 'hyperlink',
                content: [
                  {
                    nodeType: 'text',
                    marks: [],
                    value: t('HomeEquityPage.textContent2b', {
                      defaultValue: 'talk to a Mortgage Loan Officer',
                    }),
                    data: {},
                  },
                ],
                data: {
                  uri: `/home-lending/mortgage-team`,
                },
              },
              {
                nodeType: 'text',
                marks: [],
                value: t('HomeEquityPage.textContent2c', {
                  defaultValue: ' about what you may qualify for.',
                }),
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  ]

  const CalcTips = () => (
    <Trans i18nKey="HomeEquityPage.calcTips">
      <p>
        The maximum loan-to-value (LTV) that lenders allow varies based on a number of factors, but
        it is typically no more than 80% for an owner-occupied residence. To calculate your LTV
        ratio, divide the amount left to pay on your loan by the current appraised value of your
        house. For example, $200,000 left to pay on loan/$400,000 appraised value = 50% LTV.
      </p>
    </Trans>
  )

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: mortgagePage.urlPath }}
          menu={{ ...navMenus, subnav: [calculatorsPage] }}
          showSubnav
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('HomeEquityPage.pageTitle', {
              defaultValue: 'Home Equity Calculator',
            })}
            pathname="/calculators/mortgage"
          />
          <CalculatorSectionModule
            pageTitle={t('HomeEquityPage.title', {
              defaultValue: 'Mortgage Calculators',
            })}
            sections={pageData}
            calculator={<HomeEquityCalculator />}
            anchorLinkSections={anchorLinkSections()}
            calcTips={<CalcTips />}
          />
          <CrossSellToutEquity />
        </Layout>
      )}
    </StaticTemplate>
  )
}

HomeEquityPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { HomeEquityPage }
export default HomeEquityPage
